@tailwind base;
@tailwind components;
@tailwind utilities;

body > iframe {
    pointer-events: none;
}
/* npx tailwindcss-cli@latest build src/index.css -o public/dist/css/styles.css */
    
/* ::-webkit-scrollbar              { width: 6px; height: 6px;}
::-webkit-scrollbar-button       { background:#727272;}
::-webkit-scrollbar-track        { background:#616161}
::-webkit-scrollbar-track-piece  { background:#333333; }
::-webkit-scrollbar-thumb        { background:#888888; }
::-webkit-scrollbar-corner       { background:#999999; }
::-webkit-resizer     {width: 6px; height: 6px;} */


::-webkit-scrollbar {
    width: 6px; height: 6px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}
::-webkit-scrollbar-thumb {
    background: #c8c8c8;
    border-radius: 6px;
}

@layer components {
    /* .btn-blue {
      @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
    } */
    .text-input-ks {
        @apply block w-full px-4 py-3 rounded-full transition duration-150 ease-in-out text-sm font-normal sm:leading-5 border-black border mx-auto mt-2 placeholder-gray-600 focus:outline-none focus:text-gray-900 focus:border-gray-800 focus:bg-gray-100 text-gray-600;
    }
    .text-input-border {
        @apply border border-dark-400 bg-dark-400 ;
    }
    .text-input-border-error {
        @apply appearance-none block w-full px-4 py-3 rounded-full placeholder-gray-600 text-gray-700 focus:outline-none focus:border-gray-800 focus:bg-gray-900 focus:text-gray-600 transition duration-150 ease-in-out text-sm font-normal sm:leading-5;
    }
    
} 
 

.temp001{
    /* height: 100%; */
    position: static !important;
}

.pagination { 
    @apply items-center flex
}

 
.pagination > li > a,
.pagination > li > span {
    @apply relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 cursor-pointer bg-black bg-opacity-10
}

.pagination > li:nth-child(2n + 1) > a,
.pagination > li:nth-child(2n + 1) > span{
    @apply bg-opacity-5
}

.pagination>li.active>a {
 @apply bg-shine text-white   
 
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
    @apply bg-shine text-white
       
    }
    .pagination > li:first-child > a,
    .pagination > li:first-child > span {
       @apply rounded-l block
       
    }
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
        @apply rounded-r block mr-0
       
    }



.scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scroll::-webkit-scrollbar
{
	width: 4px;
	background-color: #F5F5F5;
}
.scroll::-webkit-scrollbar-thumb
{
	background-color: #FBB00C;
}
    .tableCell {
        /* Your existing styles for the table cell */
        px: 4;
        py: 3;
        whitespace-nowrap: true;
        text-center: true;
        font-medium: true;
        text-sm: true;
        capitalize: true;
      }
      
      .hoverEffect:hover {
        /* Add your hover effect styles here */
        background-color: #e0e0e0; /* Change to your desired background color on hover */
        cursor: pointer; /* Change to your desired cursor style on hover */
        /* Add any other styles for the hover effect */
      }
